<template>
  <div>
    <ResultCard
      v-for="(result, i) in results"
      :key="i"
      :name="result.fullName"
      :avatar="result.avatarLargeImage && result.avatarLargeImage.replace('-x-', '96x96')"
      :to="getDetailsLink(result.participantId)"
      :data-id="result.participantId"
      :highlighted="idHighlighted === result.participantId"
      @clickMemberImg.prevent.stop="!hideCompareButton ? $emit('compare', result.participantId) : null"
    >
      <template v-slot:info>
        <div class="result-card__info-item text-with-icon" v-if="result.number">
          <Icon
            class="icon color-muted"
            name="basketball-jersey"
            size="xs"
          />&nbsp;<span>№{{ result.number }}</span>
        </div>
        <div class="result-card__info-item text-with-icon" v-if="result.genderAgeNominationName">
          <Icon
            class="icon color-muted"
            name="pie-chart"
            size="xs"
          />&nbsp;<span>{{ result.genderAgeNominationName }}</span>
        </div>
      </template>
      <template v-if="!hideCompareButton" v-slot:action>
        <CompareButton
          :active="compareList.indexOf(result.participantId) !== -1"
          @toggle="$emit('compare', result.participantId)"
        ></CompareButton>
      </template>
      <template v-slot:cols>
        <div class="result-card__col text-semibold" style="z-index: 1">
          <ResultPosition
            class="text-lg font-monospace"
            :position="result[positionByKey]"
            :status="result.status"
            :leadersCount="absoluteLeadersCount"
          >
            <div class="text-xs mt-4"><b>{{ $t('result.position') }} ({{ positionByText.toLowerCase() }})</b></div>
          </ResultPosition>
        </div>
        <div class="result-card__col text-semibold">
          <div
            :is="result.timeOffset && result.timeOffset !== '00:00' ? 'TimeOffset' : 'div'"
            :offset="result.timeOffset"
            :result="result.resultByCalcMethod"
            style="position: relative; z-index: 1;"
          >
            <span class="text-lg font-monospace">
              {{ result.timeResultByCalcMethod || '--' }}
            </span>
            <div class="text-xs mt-4"><b>{{ $t('result.result') }}</b></div>
          </div>
        </div>
        <div class="result-card__col  text-semibold">
          <div class="text-lg font-monospace">{{ result.delayByCalcMethod ? `+${result.delayByCalcMethod}` : '--' }}</div>
          <div class="text-xs mt-4"><b>{{ $t('result.gap') }}</b></div>
        </div>
      </template>
    </ResultCard>
  </div>
</template>

<script>
import resultsMixin from './resultsMixin';

export default {
  name: 'IndividualResultsCompact',
  mixins: [resultsMixin],
  props: {
    hideCompareButton: Boolean,
  },
  methods: {
    getDetailsLink(id) {
      const route = this.$router.resolve({
        name: 'Participant',
        params: { participantId: id },
      });
      return route.href;
    },
  },
};
</script>
